import * as React from "react";
import Container from "react-bootstrap/Container";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import "./TagBit.scss";

const TagBit = () => {
  const data = useStaticQuery(
    graphql`
      query {
        womanImg: file(relativePath: { eq: "shooting-woman.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [WEBP]
              height: 280
              transformOptions: { fit: CONTAIN, cropFocus: CENTER }
            )
          }
        }
      }
    `
  );
  return (
    <section id="mission" className="tagBit">
      <Container className="text-center mb-3 sr-only">
        {/* This only appears in screen readers */}
        <h2 className="sectionHeading">Mission Statement</h2>
      </Container>
      <Container className="tagBit__cont">
        <div className="multiBox__left multiBox--regular">
          We are dedicated to providing training for the safe use of firearms
          for the blind, visually impaired, and other interested persons.
        </div>
        <div className="multiBox__right">
          <GatsbyImage
            className="img-fluid rounded float-end"
            image={getImage(data.womanImg)}
            alt="Woman Shooting Picture"
          />
        </div>
      </Container>
    </section>
  );
};

export default TagBit;
